//expression manipulations
export function humanize(str) {
    let i, frags = str.split('_');
    for (i=0; i<frags.length; i++) {
        frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
    }
    return frags.join(' ');
}

//comparing
export function isEmptyOrUndefined(value) {
    if(!value){
        return true;
    }
    if(value == undefined){
        return true;
    }
    if(value === ''){
        return true;
    }
    return false;
}