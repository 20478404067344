<template>
    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper">
        <BreadCrumb :segments="segments" :page-name="pageName"></BreadCrumb>
        <!-- Main content -->
        <section class="content">
            <div class="container-fluid">

                <div class="card card-secondary">
                    <div class="card-header">{{ user.name }}</div>
                    <form @submit="saveForm">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-sm-3" v-for="(permissions, group) in permissionGroups" v-bind:key="group">

                                    <div class="card card-outline">
                                        <div class="card-header">
                                            {{ humanize(group)}}
                                        </div>
                                        <div class="card-body">
                                            <div v-for="(permission, permissions_key) in permissions" v-bind:key="permissions_key">
                                                <div class="row">
                                                    <div class="col-sm-10" :class="permission.hasError? 'text-danger': ''">
                                                        {{humanize(permissions_key)}} <i class="far fa-info-circle fa-xs text-danger custom-tooltip" v-if="!permission.incInPackage"><span class="custom-tooltip-text">Not included in your current package!</span></i>
                                                    </div>
                                                    <div class="col-sm-2 text-right">
                                                        <div class="form-check">
                                                            <input type="checkbox" class="form-check-input is-invalid" :value="permission.val" v-model="formData.permissions" :disabled="!permission.incInPackage" @click="debug()">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div class="card-footer">
                            <label><input type="checkbox" aria-label="Checkbox for following text input" v-model="selectAll" v-on:change="selectAllPermissions"> Select All</label>
                            <button type="submit" class="btn btn-sm btn-primary float-right"><i class="fas fa-save"></i> Update</button>
                        </div>
                    </form>
                </div>

            </div><!--/. container-fluid -->
        </section>
        <!-- /.content -->
    </div>
</template>

<script>
    import BreadCrumb from "../../../components/app/common/BreadCrumb";
    import Vue from 'vue'
    import {humanize} from "@/helpers/stringHelper";
    import {getOwnUserID} from "@/helpers/userHelper";
    import {UserService} from "@/services/user-service";
    let userService = new UserService();
    export default {
        name: "UserPermissions_view",
        data: function(){
            return {
                pageName: 'User Permissions',
                segments: [
                    { link: true, text: 'Users', routeName: 'App.Users' },
                    { link: false, text: 'User Permissions' },
                ],
                user: {},
                permissionGroups: {},
                formData: {
                    id: 0,
                    permissions: []
                },
                selectAll: false,
                packagePermissions: [],
            }
        },
        components: {
            BreadCrumb
        },
        methods:{
            humanize,
            selectAllPermissions(){
                this.formData.permissions = [];
                if(this.selectAll) {
                    for (let group in this.$globalSettings.permissions) {
                        for (let permission in this.$globalSettings.permissions[group]) {
                            if(this.packagePermissions[this.$globalSettings.permissions[group][permission]] !== undefined){
                                if(this.packagePermissions[this.$globalSettings.permissions[group][permission]] == "1"){
                                    this.formData.permissions.push(this.$globalSettings.permissions[group][permission]);
                                }
                            }else{
                                this.formData.permissions.push(this.$globalSettings.permissions[group][permission]);
                            }
                        }
                    }
                }
                //console.log(this.formData.permissions);
            },
            debug(){
                //console.log(this.formData.permissions);
            },
            async loadSavedData() {
                if (this.$route.params.id !== 0) {
                    this.formData.id = this.$route.params.id;
                    let loader = this.$loading.show({container: this.$refs.formContainer});
                    let response = await userService.getUserPermissions(this.formData.id);
                    if (response.isSuccess) {
                        this.user = response.user;
                        this.packagePermissions = response.package_permissions? response.package_permissions: [];

                        this.formData.permissions = this.user.permissions? JSON.parse(this.user.permissions): [];
                        /*//console.log(userPermissions);
                        for (let prop in userPermissions) {
                            if (Object.prototype.hasOwnProperty.call(userPermissions, prop)) {
                                this.formData.permissions.push(prop);
                            }
                        }*/
                        this.preparePermissions([]);
                    } else {
                        Vue.$toast.open({message: response.message, type: 'error'});
                        await this.$router.push({name: 'App.Users'});
                    }
                    loader.hide();
                }
            },
            preparePermissions(permission_errors){
                //console.log(permission_errors);
                this.permissionGroups = {};
                for (let group in this.$globalSettings.permissions) {
                    let permissions = {};
                    for (let permission in this.$globalSettings.permissions[group]) {
                        let preparedPermission = { val: this.$globalSettings.permissions[group][permission], hasError: permission_errors.includes(this.$globalSettings.permissions[group][permission]) };
                        if(this.packagePermissions[this.$globalSettings.permissions[group][permission]] !== undefined){
                            preparedPermission['incInPackage'] = this.packagePermissions[this.$globalSettings.permissions[group][permission]] == "1";
                        }else preparedPermission['incInPackage'] = true;
                        permissions[permission] = preparedPermission;
                    }
                    this.permissionGroups[group] = permissions;
                }
                //console.log(this.permissionGroups);
            },
            async saveForm(e) {
                e.preventDefault();
                let loader = this.$loading.show();
                userService.formDataUserPermissions = this.formData;
                let response = await userService.saveUserPermissions();
                if (response.isSuccess) {
                    Vue.$toast.open({message: response.message, type: 'success'});
                    this.preparePermissions([]);
                    if(this.$route.params.id === getOwnUserID()){
                        await this.$router.push({name: 'App.Dashboard'});
                    }
                }else{
                    Vue.$toast.open({message: response.message, type: 'error'});
                    if(response.save_error_permissions.length > 0){
                        this.preparePermissions(response.save_error_permissions);
                    }
                }
                loader.hide();
            }
        },
        mounted() {

            this.loadSavedData();
        }
    }
</script>

<style scoped>

</style>