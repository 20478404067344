import { render, staticRenderFns } from "./UserPermissions_view.vue?vue&type=template&id=f701cfa8&scoped=true&"
import script from "./UserPermissions_view.vue?vue&type=script&lang=js&"
export * from "./UserPermissions_view.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f701cfa8",
  null
  
)

export default component.exports